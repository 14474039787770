export const APP_NAME = 'Drive Driven EU Admin';
export const PAYMENTS_ADMIN_ROLE = 'PaymentsAdmin';

export const ENDPOINTS = {
  INVOICES: '/api/v1/billing/invoice',
};

export const PATHS = {
  HOME: '/',
  REPORTING: '/reporting',
  USER: '/user',
};

export const REGEX = {
  NOHTML: /^([^><]*)$/,
  COMPANY_NAME: /^[A-Za-z0-9\s,'&()-]*$/,
  COMPANY_NUMBER: /^$|^([A-Za-z]{2}|)\d{8}$/,
  EMAIL: /^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/,
  NAME: /^$|^[a-zA-Z ,.'-]+$/,
  PHONE_NUMBER: /^([^a-zA-Z£$%^&*€!{}[\]";:/\\><`~]*)$/,
  TIME: /^(2[0-3]|[01]?\d):([0-5]?\d)$/,
  TIMERAW: '(2[0-3]|[01]?[0-9]):([0-5]?[0-9])',
};

export const ERROR_MESSAGES = {
  400: 'Bad Request',
  401: 'Unauthorised - Log in again',
  403: 'Forbidden - Log in as a permitted user',
  404: 'Not found',
  409: 'Already exists',
  500: 'Server error',
  503: 'Server error',
};

export const URL_DATE_FORMAT = 'yyyy-MM-dd';
